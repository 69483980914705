import React from 'react';
import { Link } from 'react-router-dom';

export const NotStartedWelcome: React.FC = () => {
  return (
    <>
      <p>
        Welcome to the 2025{' '}
        <strong style={{ color: '#fb4f14', fontWeight: 'bold' }}>
          IHG
        </strong>
        , 8 Week Challenge! Please check out all of the tabs above to
        see the resources available to you during the next 8 weeks.
      </p>

      <p>
        From the 13th to 27th of January, you will need to load your
        initial vitals. This will include your goals, initial photos &
        measurements. Please refer to{' '}
        <Link to="/about">
          <span>About</span>
        </Link>{' '}
        and{' '}
        <Link to="/prizes">
          <span>Prizes</span>
        </Link>{' '}
        for more information & FAQs.
      </p>
    </>
  );
};

export const FinalWelcome: React.FC = () => {
  return (
    <>
      <p>
        From the 9th to 15th of March, you will need to load your
        final vitals. This will include photos, measurements, fitness
        testing and a written testimonial of what you have achieved.
        Please refer to the 'About' and 'Prizes' icon above for more
        information and FAQs.
      </p>
    </>
  );
};

export const FinishedWelcome: React.FC = () => {
  return (
    <>
      <p>
        Congratulations on completing your Healthwise Global 8 Week
        Challenge!
      </p>
    </>
  );
};
