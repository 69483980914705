import React from 'react';

export default () => {
  return (
    <div className="content">
      <div className="content-prizes-section">
        <h2>Global Prizes</h2>
        <ul className="flex flex-col gap-y-4 bulleted">
          <li>
            <strong>8WC Warrior</strong> – Global Winner gets ONE
            TICKET TO GLOBAL BALL 2025 IN LOS ANGELES! (Or $1000 AUD
            if you have already qualified to Global with your work
            targets) (Winner must submit everything – photos, story &
            measurements)
          </li>
          <li>
            $1000 AUD "Best Physical Change" – Global Winner (must
            submit before & after photos)
          </li>
          <li>
            $1000 AUD "Most Inspirational Story" - Global Winner (must
            submit story/journey/essay)
          </li>
          <li>
            8 x Garmins to be won in our #snapandshare mini-challenges
            (1 every week)
          </li>
        </ul>
      </div>

      <div className="content-prizes-section">
        <h2>Local Prizes</h2>
        <p>
          Contact your local Healthwise rep for local prizing details
        </p>
        <ul className="bulleted">
          <li>NZ</li>
          <li>UK</li>
          <li>Americas (including CAN, USA, LATAM/MEX)</li>
          <li>Australia</li>
          <li>Asia</li>
        </ul>
      </div>

      <div className="content-prizes-section">
        <h2>ENTRIES</h2>

        <p>
          Entries not completed in accordance with these terms and
          conditions are ineligible for any prize.
        </p>

        <p>Entrants must be over 18 years of age.</p>

        <p>
          Employees and the immediate families of the promoters and
          sponsors are ineligible to enter.
        </p>

        <p>
          The promoter is Healthwise Global ACN 003 377 188 / ABN 25
          003 377 188.
        </p>

        <p>
          Prizes are as advertised on your entity's Healthwise
          webpage.
        </p>

        <p>The prizes are not transferable.</p>

        <p>
          Only clients of Healthwise Global are eligible to enter.
        </p>

        <p>
          Content will only be available on the website for the
          duration of the challenge.
        </p>
      </div>

      <div className="content-prizes-section">
        <h2>Photographs & Measurements</h2>

        <p>
          Entrants must submit amateur photographs. Photographs that,
          in the opinion of the Promoter, are of a professional nature
          will result in disqualification of the entrant.
        </p>

        <p>
          Photographs must be taken of the entrant in active wear,
          swimwear or underwear.
        </p>

        <p>
          Photographs must be full length and taken no more than 3
          metres from the camera.
        </p>

        <p>
          The entrant must submit a front and a side shot at the start
          and end of the competition.
        </p>

        <p>Photographs must be in full colour.</p>

        <p>
          The entrant must be holding a copy of that days' national
          newspaper or the 8 Week Challenge Week 1/Week 8 template on
          a tablet, smart phone, laptop or printed version. The
          newspaper or Week 1/Week 8 template must not cover any body
          part of the entrant. Mobile tablets are acceptable so long
          as the judges can clearly see the newspaper headline on the
          screen of the tablet.
        </p>

        <p>
          Healthwise Global can accept no responsibility for any
          injuries incurred whilst taking your measurements. If you
          are unsure as to the technique of any measurements required
          then you must seek the help of a health professional before
          beginning.
        </p>
      </div>

      <div className="content-prizes-section">
        <h2>Judging</h2>

        <p>
          The winners will be decided by all Healthwise Global
          full-time employees. The individual category winners will be
          determined by the following criteria:
        </p>

        <p>
          <strong>Category 1: Best Physical Change</strong> will be
          determined by the greatest overall positive change in body
          shape and lifestyle from the opening date to the closing
          date. This is determined by the "before" and "after" photos
          as well as the "before" and "after" body girth measurements.
          (Written essay/story does not need to be submitted to win
          this category).
        </p>

        <p>
          <strong>Category 2: Most Inspirational Story</strong> will
          be determined by the most inspirational story/journey/essay
          submitted in the final vitals at the end of the challenge.
          (Photos do not need to be submitted for challengers to win
          this category).
        </p>

        <p>
          <strong>Category 3: “8WC Warrior” Winner</strong> will be
          determined by the greatest overall positive change in their
          body shape and lifestyle from the opening date to the
          closing date. This is determined by the "before" and "after"
          photos, "before" and "after" body girth measurements and
          written essays.
        </p>

        <p>Photographs determine 40% of the total score.</p>

        <p>Written essays determine 40% of the total score.</p>

        <p>
          Measurements (body measurements and strength tests)
          determine 20% of the total score.
        </p>

        <p>
          The Promoter may contact the entrants' family, friends, and
          / or work colleagues to ensure the information provided is
          accurate.
        </p>

        <p>The winners will be contacted by phone and email.</p>

        <p>
          The judge’s decision is final and no correspondence will be
          entered into.
        </p>

        <p>
          The Promoter reserves the right to refuse a winner any or
          all aspects of the prize, if the Promoter determines, at
          their absolute discretion, that a winner is not in the
          mental or physical condition necessary to be able to safely
          participate in a prize. It is a condition of accepting the
          prize that the winner may be required to sign a legal
          release in a form determined by the Promoter in its absolute
          discretion.
        </p>

        <p>
          Prize values are the recommended retail value as provided by
          the supplier and are correct at time of printing.
        </p>

        <p>
          The Promoter shall not be liable for any loss or damage
          whatsoever which is suffered (including, but not limited to,
          direct or consequential loss) or for any personal injury
          suffered or sustained in connection with the Healthwise
          Global Challenge or any other prize/s, except for any
          liability which cannot be excluded by law.
        </p>

        <p>
          Healthwise Global and their associated agencies and
          companies associated with this promotion will take no
          responsibility for prizes damaged or lost in transit.
        </p>

        <p>
          All entrant information becomes the property of the Promoter
          and can be used at their discretion.
        </p>

        <p>
          Entrant information including photographs cannot be used in
          any publication or form of media unless permission is given
          by Healthwise Global.
        </p>

        <p>
          Healthwise Global will take all due care to ensure that all
          entrant information is kept secure.
        </p>

        <p>
          In the event that, for any reason whatsoever, a winner does
          not take an element of the prize at the time stipulated by
          the promoter, then that element of the prize will be
          forfeited by the winner and cash will not be awarded in lieu
          of that element of the Prize.
        </p>

        <p>
          Healthwise Global recommends that entrants seek medical
          advice before undertaking any new approach to exercise.
        </p>

        <p>
          The category winners may be filmed / photographed for a
          magazine / television story. Category winners must be
          available for promotional opportunities over a period of 12
          months following the Healthwise Global 8 Week Challenge
          2024.
        </p>

        <p>
          All potential winners may be drug tested to ensure no
          performance-enhancing substances have been used. Any entrant
          in the Healthwise Global 8 Week Challenge 2024 who tests
          positive to a drug test will be immediately disqualified
          from the competition.
        </p>

        <p>
          Any entrant who refuses to comply with the above terms and
          conditions will be disqualified.
        </p>
      </div>
    </div>
  );
};
