import About from './About';
import Library from './Library';
import Nav from './Nav';
import Prizes from './Prizes';
import React from 'react';
import store from './redux';
import VitalsFinal from './VitalsFinal';
import VitalsInit from './VitalsInit';
import Workouts from './Workouts';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { useContext } from 'react';
import './App.css';
import { ChallengeStageContext } from './context/challengeStageContext';
import { Home } from './Home';
import { SnackbarProvider } from 'notistack';

export const App: React.FC = () => {
  const now = new Date();
  const context = useContext(ChallengeStageContext(now));

  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            autoHideDuration={2000}
            domRoot={document.getElementById('react-notification')!}
          >
            <Nav />
            <Switch>
              <Route path="/about" component={About} />
              <Route path="/prizes" component={Prizes} />
              {context.shouldDisplayInitialVitals ? (
                <Route
                  path="/initial-vitals"
                  component={VitalsInit}
                />
              ) : null}

              {context.shouldDisplayFinalVitals ? (
                <Route path="/final-vitals" component={VitalsFinal} />
              ) : null}

              <Route path="/library" component={Library} />
              <Route path="/workouts" component={Workouts} />
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </SnackbarProvider>
        </div>
      </Router>
    </Provider>
  );
};
