import React from 'react';

export default () => {
  return (
    <div className="content">
      <div className="content-about-section">
        <h2>How Does it Work?</h2>
        <p>
          Once your challenge begins your starting point will be
          benchmarked by taking your photos/measurements and setting
          your goals.
        </p>
        <p>
          You will then be provided with workouts, a suggested food
          guide, mindfulness plans and much more from the Healthwise
          Global team.
        </p>
        <p>
          At the end of the challenge you will retake your photos,
          measurements and provide a testimonial to show just how far
          you've come. Once you load these vitals, you will be in the
          running to win your share of the prizes!
        </p>
      </div>

      <div className="content-about-section">
        <h2>
          The Healthwise Global 8 Week Challenge will provide you
          with:
        </h2>
        <div className="content-about-sub-section">
          <h3>Nutrition plans</h3>
          <p>
            Simple and tasty recipes with suggested weekly meal plans
          </p>
        </div>

        <div>
          <h3>Workout plans</h3>
          <p>
            Easy to follow weekly workout plans for every level of
            fitness
          </p>
        </div>

        <div>
          <h3>Mindfulness plans</h3>
          <p>
            Tips and strategies to enhance your emotional wellbeing
          </p>
        </div>

        <div>
          <h3>Exercise videos</h3>
          <p>
            Weekly videos to ensure you are getting the most from your
            workouts
          </p>
        </div>

        <div>
          <h3>Online health hacks</h3>
          <p>
            Weekly topics to ensure you have the best chance of
            reaching your goals
          </p>
          <p>
            As well as the awesome programs outlined above, there will
            also be continuous support in the form of emails and
            social media. And of course your Healthwise Representative
            will always be on hand to answer any questions to support
            you towards your goals.
          </p>
          <i>
            Please note that all of the content will only be available
            on the challenge website for the duration of the
            challenge.
          </i>
          <p>
            Of course, there is no need to follow our plans. If you
            have a proven, sustainable plan to ensure you improve your
            health over the 8 weeks then you can follow your own plan.
            The purpose of this challenge is to make you accountable
            to your goals, in turn, implementing a healthy lifestyle
            that can be sustained into the future!
          </p>
        </div>
      </div>

      <div className="content-about-section">
        <h2>FAQs</h2>
        <div className="content-about-sub-section">
          <p className="content-about-question">
            Q: When can I register for the 8 Week Challenge 2025?
          </p>
          <p>
            A: Registrations are currently open and will close on
            January 27, 2025.
          </p>
        </div>

        <div className="content-about-sub-section">
          <p className="content-about-question">
            Q: When does the 8 Week Challenge start?
          </p>
          <p>
            A: Your challenge begins when you load your initial goals,
            photos and measurements. The ability for you to load these
            vitals will open on January 13, 2025 and close at midnight
            on January 27, 2025. All entrants must enter their
            initial vitals in accordance with the rules.
          </p>
        </div>

        <div className="content-about-sub-section">
          <p className="content-about-question">
            Q: When does the 8 Week Challenge end?
          </p>
          <p>A: March 9, 2025.</p>
        </div>

        <div className="content-about-sub-section">
          <p className="content-about-question">
            Q: How much does it cost to register?
          </p>
          <p>A: Registration is $AUD 25, or the equivalent in your currency.</p>
        </div>

        <div className="content-about-sub-section">
          <p className="content-about-question">
            Q: I have already entered the challenge and I want to make
            changes to my entry?
          </p>
          <p>
            A: To make any changes to your entry please contact your
            Healthwise Representative or email
          </p>
          <a
            className="email"
            href="mailto:info@healthwiseglobal.com"
          >
            info@healthwiseglobal.com
          </a>
        </div>
      </div>
    </div>
  );
};
