import { faFile, faVideo } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import './Nav.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default () => {
  return (
    <div className="content">
      <div className="content-library-section">
        <h2>My Nutrition</h2>
        <ul>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/8_Week_Challenge_Nutrition_Guide2024.pdf"
              target="_blank"
            >
              Suggested Food Guide
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/suggested-menu-planner-1-8-wks-jan-2025-editable.pdf"
              target="_blank"
            >
              Suggested Weekly Menu
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/EditableMealPlannerJan2021.pdf" target="_blank">
              Blank Weekly Menu
            </a>
          </li>
        </ul>
      </div>
      <div className="content-library-section">
        <h2>Bonus Recipes</h2>
        <ul>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/HW1521126646-CHALLENGE-8-WK-2025_MediterraneanRecipes.pdf"
              target="_blank"
            >
              Quick Mediterranean Style Meals
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/HW1521126646-CHALLENGE-8-WK-2025_singleserve_recipes.pdf"
              target="_blank"
            >
              Single Serve Recipes
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/984587069_HW_8_Wk_Challenge_receipesSMOOTHIESANDSALADS.pdf"
              target="_blank"
            >
              Smoothies and Salads
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/984587069_HW_8_Wk_Challenge_smoothie_receipesBONUS.pdf"
              target="_blank"
            >
              More Smoothies
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/DD2009_8_Week_Challenge_Meatballs.pdf"
              target="_blank"
            >
              Meatballs
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/DD_4717_8_Week_Challenge_Soups_MR.pdf"
              target="_blank"
            >
              Soups
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/DD_7141_8_Week_Challenge_bonus_recipe_Slices_LR3.pdf"
              target="_blank"
            >
              Muesli Slices
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/HW1244331122-8_Wk_Challenge_recipes-2024_aussiedelgihts.pdf"
              target="_blank"
            >
              Australia Day Delights
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/HW1244331122-8_Wk_Challenge_recipes-2024_oats.pdf"
              target="_blank"
            >
              Overnight Oats
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/HW1244331122-8_Wk_Challenge_recipes-2024_snacks.pdf"
              target="_blank"
            >
              Snacks
            </a>
          </li>
        </ul>
      </div>
      <div className="content-library-section">
        <h2>My Fitness</h2>
        <ul>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/GettingStartedJan2021.pdf" target="_blank">
              Getting Started
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/BeginnerProgramJan2021.pdf" target="_blank">
              Beginner Program
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/IntermediateProgramJan2021.pdf" target="_blank">
              Intermediate Program
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/AdvancedProgramJan2021.pdf" target="_blank">
              Advanced Program
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/WeeklyExerciseTrackerJan2020.pdf"
              target="_blank"
            >
              Exercise Tracker
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/FitnessTestJan2021.pdf" target="_blank">
              Fitness Test
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/ResistanceTrainingProgram.pdf" target="_blank">
              Resistance Training Program
            </a>
          </li>
        </ul>
      </div>
      <div className="content-library-section">
        <h2>My Mindfulness</h2>
        <ul>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/8_Wk_Challenge_2022_Wellness_Task.pdf"
              target="_blank"
            >
              Wellness Task and Habit Tracker
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/FoundationsOfMindfulnessJan2021.pdf"
              target="_blank"
            >
              Foundations of Mindfulness
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/MyCircleOfBalanceJan2021.pdf" target="_blank">
              My Circle of Balance
            </a>
          </li>
        </ul>
      </div>

      <div className="content-library-section">
        <h2>Back Chat</h2>
        <ul>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Adam_Dicker_BackChat_Success_Failure.pdf"
              target="_blank"
            >
              Adam Dicker - The Bridge between Success and Failure
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Anna_Fisher_BackChat_FamilyLifeBalance.pdf"
              target="_blank"
            >
              Anna Fisher - Finding a Family Balance
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/DanBasley_BackChat_StopMakingExcuses.pdf"
              target="_blank"
            >
              Dan Basley - Stop Making Excuses
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Jack_Wardingley_BackChat_GroupTraining.pdf"
              target="_blank"
            >
              Jack Wardingley - Benefits of group and team training
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Jason_Ruttley_BackChat_Discipline.pdf"
              target="_blank"
            >
              Jason Ruttley - Discipline
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Kate_Laird_Back_Chat_healthyFamily2.01.pdf"
              target="_blank"
            >
              Kate Laird - Healthy Family Living
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Katina_Karydas_BackChat_Discipline.01.pdf"
              target="_blank"
            >
              Katina Karydas - Preparation is key
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/Ross_Larsen_BackChat.pdf" target="_blank">
              Ross Larsen - Flexible Dieting
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Stephen_Cormack_BackChat_YourFuture.pdf"
              target="_blank"
            >
              Stephen Cormack - Your Future
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Tod_Horton_BackChat_PrioritiseYourHealth.pdf"
              target="_blank"
            >
              Tod Horton - Prioritise Your Health
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/Tory_Wright_BackChat_MentalHealth.pdf"
              target="_blank"
            >
              Tory Wright - Mental Health
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a href="/Vanessa_BC_Gratitude.pdf" target="_blank">
              Vanessa Ciampi - Gratitude
            </a>
          </li>
        </ul>
      </div>

      <div className="content-library-section">
        <h2>My Vitals</h2>

        <ul>
          <li>
            <FontAwesomeIcon icon={faVideo} />
            <a
              href="https://www.youtube.com/watch?v=Gul37OAkU5A"
              target="_blank"
            >
              How to measure your vitals
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faVideo} />
            <a
              href="/HW1521126646-WEB-CHALLENGE-WEEK1SIGN2025.jpg"
              target="_blank"
            >
              Week 1 Image
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFile} />
            <a
              href="/jan25/DD2009_8 Week Challenge_Vitals Form_Editable.pdf"
              target="_blank"
            >
              Editable Vitals Form
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
